import './App.css';
import * as React from 'react';
import Container from '@mui/material/Container';
import  {useRef,useEffect,useState} from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import axios from 'axios';
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';



mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

function App() {
 let defaultLocation= [21.1458, 79.0882 ] // {lat, long}
      const mapContainer = useRef(null);
      const map = useRef(null);

      const[startLocation,setStartLocation]=useState([]);
      const[endLocation,setEndLocation]= useState([]);
      const [zoom, setZoom] = useState(16);


      const[basicFare,setBasicFare]=useState(0);
      const[standardFare,setStandardFare]=useState(0);
      const[premiumFare,setPremiumFare]=useState(0);


  function _suggestionStartSelect(result, lat, lng, text){
          console.log(result, lat, lng, text);
          setStartLocation([lat,lng]);

          //create a html element for each feature
          const el=document.createElement('div');
          el.className='marker';

          //make a marker for each feature and add to the map
          new mapboxgl.Marker(el).setLngLat([lng,lat]).addTo(map.current);

          // move the map to seleted location

          map.current.flyTo({
            center: [lng, lat],
            essential: true , 
            zoom:16
          });


  }


function _suggestionEndSelect(result, lat, lng, text){
        console.log(result, lat, lng, text);
        setEndLocation([lat,lng]);

        //create a html element for each feature
        const el=document.createElement('div');
        el.className='marker';

        //make a marker for each feature and add to the map
        new mapboxgl.Marker(el).setLngLat([lng,lat]).addTo(map.current);

        // move the map to seleted location

   map.current.flyTo({
      center: [lng, lat],
      essential: true , 
      zoom:11
    });


  }


    function hitApi(){
       console.log("hit api");

    const res = axios.get(`https://api.mapbox.com/directions/v5/mapbox/driving/${startLocation[1]},${startLocation[0]};${endLocation[1]},${endLocation[0]}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`) 
          .then(function (response){
            //handle success
            console.log("mapbox res: ", response);


        map.current.addSource('route',{
            'type': 'geojson',
            'data': {
            'type': 'Feature',
            'properties':{},
            'geometry':{
            'type':'LineString',
            'coordinates': response.data.routes[0]["geometry"]["coordinates"]
            }
          }
        });
        
          map.current.addLayer({
            'id': 'route',
           'type': 'line',
           'source': 'route',
           'layout':{
            'line-join':'round',
            'line-cap':'round'
           },

           'paint': {
            'line-color': '#888',
            'line-width': 8,
          
          }
        });

        //Find distance

       let distance=response.data.routes[0]["distance"]/1000; //kilometer

          setBasicFare(parseFloat(20 + (distance * 5)).toFixed(2)) // formula : basefare + distance * rate_per_km
          setStandardFare(parseFloat(100 + (distance * 7)).toFixed(2));
          setPremiumFare(parseFloat(150 + (distance * 10)).toFixed(2));

      });
      
      }

    useEffect(() => {
            if (map.current) return; // initialize map only once
            map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [defaultLocation[1], defaultLocation[0]], //long,lat
            zoom:  zoom
          
    });

          // //create a html element for each feature
          // const el=document.createElement('div');
          // el.className='marker';

          // //make a marker for each feature and add to the map
          // new mapboxgl.Marker(el).setLngLat([lng,lat]).addTo(map.current);


        });

  
  return (
      <> 
      <Container>
        OLAX
            <Grid container margin={2}>
            <Grid item md={50}>
            <div>
              <div ref={mapContainer} className="map-container" />
          </div>
          </Grid>


<Grid item md={100} padding={3}>
          <MapboxAutocomplete
                    publicKey={mapboxgl.accessToken}
                    inputClass="form-control search"
                    onSuggestionSelect={_suggestionStartSelect} 
                    country="in" 
                    style={{borderbottom:"2px solid black", color:'red'}}
                    resetSearch={false}
                    placeholder="&#x25FC;   Start Location">

             </MapboxAutocomplete>

             <MapboxAutocomplete
                    publicKey={mapboxgl.accessToken}
                    inputClass="form-control search"
                    onSuggestionSelect={_suggestionEndSelect} 
                    country="in" 
                    style={{borderbottom:"2px solid black"}}
                    resetSearch={false}
                    placeholder="&#x25FC;   End Location">

             </MapboxAutocomplete>
                    
    <Button onClick={()=>{hitApi();}}>Find Route</Button>
    
    </Grid>

      <Grid container spacing={2}>
      <Grid item md={4}>
      <Box>
      <Card sx={{ minWidth: 265 }} margin={'2px'} padding={'3px'} height={'30px'}>
            <CardContent style={{backgroundColor: "white"}}> 
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                   Basic
              </Typography>
              <Typography variant="h5" component="div">
                   {basicFare}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                   BasicFare
              </Typography>
              <Typography variant="body2">

              <Grid container spacing={3}>
                <Grid item md={4}>
                          <CardMedia
                              component="img"
                              style={{ width: '97px', height: '50px' }}
                              image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT78pEO29ec5EGaYWRgzCSHpzGXDe5YKFOqxQ&usqp=CAU"
                              alt="Auto"
                            />
                  </Grid>

                <Grid item md={4}>
                   <CardMedia
                    component="img"
                    style={{ width: '95 px', height: '50px' }}
                    image="https://w7.pngwing.com/pngs/463/913/png-transparent-car-motorcycle-helmets-computer-icons-bicycle-small-motorcycle-thumbnail.png"
                    alt="Auto"
                  />
                </Grid>
                </Grid>
              </Typography>
              </CardContent> 
              <CardActions>
                     <Button size="small" >Learn More</Button>
               </CardActions>
               </Card>
                  
             </Box>
             </Grid>


          <Grid item md={4}>
    <Box>
    <Card sx={{ minWidth: 275 }} margin={'2px'} padding={'3px'} height={'30px'}>
              <CardContent style={{backgroundColor: "white"}}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Standard
                </Typography>
               <Typography variant="h5" component="div">
                  {standardFare}
               </Typography>
               <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  StandardFare
               </Typography>
               <Typography variant="body2">
               <Grid container spacing={5}>
                 <Grid item md={4}>
                   <CardMedia
                      component="img"
                      style={{ width: '100px', height: '50px' }}
                      image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgA2S1803ggwUWbYXikfvjY-jSRtdLMGYclw&usqp=CAU"
                      alt="Auto"
                   />
                 </Grid>

                 <br/>
                 <Grid item md={4}>
                    <CardMedia
                      component="img"
                      style={{ width: '120px', height: '40px' }}
                      image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTk9z1assS8vX4Uwkna8Nnclu5-0gPWx5feTA&usqp=CAU"
                      alt="Auto"
                    />
                  </Grid>
                 </Grid>
              </Typography>
              </CardContent>
                <CardActions>
                     <Button size="small">Learn More</Button>
                 </CardActions>
              </Card>
         </Box>
              </Grid>


          <Grid item md={4}>
      <Box>
            <Card sx={{ minWidth: 275 }} margin={'2px'} padding={'3px'} height={'30px'}>
                <CardContent style={{backgroundColor: "white"}}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Premium
                  </Typography>
                  <Typography variant="h5" component="div">
                    {premiumFare}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    PremiumFare
                  </Typography>
                  <Typography variant="body2">
                  <Grid container spacing={5}>
                <Grid item md={4}>
                  <CardMedia
                    component="img"
                    style={{ width: '100px', height: '50px' }}
                    image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw8OsxIrRQPfEwiJBzSYjZyRod8wiryFgkNg&usqp=CAU"
                    alt="Auto"
                  />
                      </Grid>
                      <Grid item md={4}>
                  <CardMedia
                    component="img"
                    style={{ width: '100px', height: '50px' }}
                    image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJyWz78MZBkRoe_ZEeeULg-7lCennNwo_eLA&usqp=CAU"
                    alt="Auto"
                  />
                      </Grid>
                      </Grid>
                  </Typography>
                  </CardContent>
                  
                  <CardActions>
                    <Button size="small">Learn More</Button>
                    
                    </CardActions>
                      
                  </Card> 
                
           </Box>
            
           </Grid>
           </Grid>
           </Grid>
          </Container>
       </>

);
}

export default App;
